/* .mid-nav button{
    background:none;
    border: none;
} */
.change{
    display: flex;
    flex-direction: column;
    text-align:start;
    padding-top:60px ;
    margin-top: 20px;
    padding-left: 80px;
    /* background-image: url(../../../public/assets/Group\ 87.png); */
    height: 200px;
    background-size:cover;
  }

.ipolink{

   text-align: start;
    text-decoration: none;
    color: #FFFFFF;
    font-size: 20px;
   
  }
  .uldivipo{
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }

  .heading-tata{
    color: white;
    font-size:40px;
    font-weight:700;
    line-height: 40px;

    }


    /* mid div */
.mid{
    width: 100%;
    text-align: center;
   
  
}
.mid-nav{
    display: flex;
    justify-content: center;
    text-align: center;
    gap:200px;
    padding-top:20px;
    font-weight: 400;
    font-size: 24px;
    line-height: 24px;
    color: #323232;
    

}


@media screen and (max-width: 768px) {
    /* Adjustments for smaller screens */
    .midhead5 {
        width: 200px; /* Example adjustment */
        font-size: 10px; /* Example adjustment */
        display: flex;
        margin-left: 3.5rem;
    }
    .mid-blocks{
        display: flex;
        flex-wrap: wrap;
        width: 100%;  
        height: 100%;
        gap: 20px;
    }
    .Ipo{
        width: 100%;
        height: 100%;
     align-content: center;
    }
   .change{
        display: flex;
        flex-direction: column;
        text-align:start;
        padding-top:10px;
         /* background-image: url(../../../public/assets/Group\ 87.png); */
        height: 200px;
        }
        .uldivipo{
            display: flex;
            flex-direction:row;
            align-items: center;
            text-align: center;
            justify-content: space-evenly;
            gap: 80px;
        
          }
    .ipolink{
      text-align: start;
        text-decoration: none;
        color: #FFFFFF;
        font-size: 11px;
        margin-left: -80px;
        gap: -100px;
      }
      
      .heading-tata{
        color: white;
        font-size:30px;
        font-weight:500;
        text-align: center;
        line-height: 30px;
        margin-left: -80px;
        margin-top: 40px;
    
        }
.mid{
  width: 100%;
  text-align: center;
  margin-top: 10px; 
  margin-bottom:70px;
          
 }
 .mid-nav{
    display: flex;
    flex-direction: row;
    justify-content:center;
    text-align: center;
    gap:0px;
    padding-top:20px;
    font-weight: 400;
    font-size: 22px;
    line-height: 20px;
    padding:4px;
}



}