header.header-head .collapse {
  visibility: visible;
}

.container-fluid {
  max-width: 1440px;
}

a.navbar-brand span {
  font-size: 25px;
  padding: 2px 0 0px 6px;
  font-family: 'Font-Helvetica-bold';

}

header.header-head a.navbar-brand {
  font-size: 36px;
  font-weight: 600;
  color: #323232;
}

.dropdown-item:active {
  background-color: #fff;
  color: #000;
}

.collapse .navbar-nav .nav-item a {
  color: #323232;
  font-size: 14px;
  font-family: 'Font-Helvetica-bold';

}

header.header-head ul.navbar-nav li.nav-item a.nav-link {
  font-weight: 500;
  line-height: 45px;
  font-family: 'Font-Helvetica-bold';
  margin-right: 14px;
}

.nav-item.dropdown .dropdown-toggle::after {
  content: none;
}

.dropdown-menu {
  margin: 0;
  border: none;
  border-radius: 0;
}

a.Header-a {
  margin: 0 18px 0 50px;
  cursor: pointer;
  text-decoration: none;
  color: #323232;
}

a {
  color: #323232;
}

ul.dropdown-menu {
  text-align: right;
  right: 0;
  left: auto;
  top: 30px;
}

.dropdown.shopping .dropdown-toggle {
  background-color: transparent;
  border: none;
  padding: 0;
}

.dropdown.shopping .dropdown-menu li .dropdown-item {
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
}

.nav-item.dropdown .dropdown-menu {
  right: 0;
  left: auto;
  top: 65px;
  padding: 10px;
}

a.Header-a span {
  font-size: 14px;
  font-weight: 500;
  padding: 0 0 0 10px;
  font-family: 'Font-Helvetica-bold';
}

.dropdown.shopping {
  position: relative;
  top: -4px;
}

a:hover {
  color: #000;
}

.nav-item.dropdown.shopping .dropdown-menu {
  top: 25px;
}

.header-3 a i.fa-solid {
  font-size: 22px;
}

ul.dropdown-menu .dropdown-item {
  font-weight: 600;
  padding: .30rem 0rem;
}

.navbar-container {
  max-width: 1450px;
}

/* .dropdown .dropdown-menu.open-auto {
  display: block;
  padding: 7px 10px;
} */

.bag-icon svg {
  position: absolute;
  top: -6px;
  z-index: -1;
  right: -19px;
  cursor: pointer;
}

.dropdown-item:active {
  background-color: transparent;
}

.dropdown-item:hover {
  background-color: transparent;
}

header.header-head {
  background-color: transparent;
}

.header-head {
  position: fixed;
  background-color: transparent;
  transition: background-color 0.3s ease;
}


ul.dropdown-menu.account li:last-child {
  border-top: 1px solid #323232;
  margin-top: 10px;
  padding: 4px 0;
}

.header-head.scrolled {
  background-color: white;
}


.dropdown .dropdown-toggle::after {
  content: none;
}

.shopping-list img:first-child {
  width: 15px;
  height: 16px;
  margin: 4px 4px 0 0;
}

ul.dropdown-menu.account {
  text-align: left;
  padding: .5rem 1rem;
}

.dropdown-menu {
  min-width: auto;
}

/* =============== */

@media (max-width: 992px) {
  a.Header-a {
    margin: auto;
  }

  header.header-head {
    /* background-color: #fff; */
    /* width: 100%; */
    /* box-shadow: 0px 0px 13px 1px #d1d1d1; */
  }

  .term-headings {
    margin-top: 100px;
  }

}

/* .navbar{
  box-shadow: 0px 5px 10px 0px #aaa;
  position: fixed;
  width: 100%;
  background: #fff;
  color: #000;
  opacity: 0.85;
  z-index: 100;
} */

.navbar-container {
  display: flex;
  justify-content: space-between;
  height: 64px;
  align-items: center;
}

.menu-items {
  order: 2;
  display: flex;
  align-items: center;
}

.logo {
  font-size: 2.3rem;
}

.menu-items li {
  list-style: none;
  font-size: 1.3rem;
}

.navbar a {
  color: #322323;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  font-size: 14px;
  font-family: 'Font-Helvetica-bold';
  cursor: pointer;
}

.header-3 {
  margin-bottom: 8px;
}

.mobile-icon svg {
  display: none;
}

ul.menu-items .nav-link {
  padding: 1.5rem .85rem 1.8rem;
}

li.nav-item.dropdown img {
  display: none;
}

li.nav-item.dropdown {
  color: #322323;
  text-decoration: none;
  font-weight: 500;
  transition: color 0.3s ease-in-out;
  font-size: 14px;
  font-family: 'Font-Helvetica-bold';
  cursor: pointer;
}

.navbar a:hover {
  color: #565656;
}

.navbar-container input[type="checkbox"] {
  visibility: hidden;
}

@media (max-width: 768px) {
  .navbar {
    opacity: unset;
  }
  .shopping-bag {
    order: -1;
}
li.nav-item.dropdown img {
  display: block;
}

  .bag-icon svg {
    position: absolute;
    top: -50px;
    z-index: 0;
    right: 23px;
    cursor: pointer;
  }

  .dropdown-toggle.show img {
    transform: rotate(180deg);
}

  .nav-link img {
    max-width: 16px;
    height: 18px;
    transition: all 0.2s ease-in-out;
}

  .bag-icon span {
    position: absolute !important;
    top: -45px !important;
    right: calc(35px - 2px);
    z-index: 1;
    left: auto !important;
  }

  .dropdown.shopping ul li {
    border: none;
  }

  .nav-item.dropdown.shopping .dropdown-menu {
    position: absolute;
    right: auto;
    left: -100px;
  }

  .dropdown.shopping .dropdown-menu li .dropdown-item {
    font-size: 16px;
  }

  .dropdown.shopping i.fa-solid {
    padding-right: 50px;
  }

  .nav-item.dropdown .dropdown-menu {
    left: 0;
    right: auto;
    top: auto;
    position: static;
    text-align: left;
    font-size: 16px;
  }

  ul.dropdown-menu.open-auto li a {
    font-size: 16px;
  }

  ul.dropdown-menu.open-auto li:first-child {
    margin: 0;
  }

  ul.menu-items.open-auto li:first-child {
    margin-top: 0px;
  }

  .navbar-container input[type="checkbox"],
  .navbar-container .hamburger-lines {
    display: block;
  }

  .mobile-icon svg {
    display: block;
  }

  .navbar-container {
    display: block;
    position: relative;
    height: 64px;
  }

  .menu-items .navbar a {
    font-size: 16px;
    margin-left: 20px;
  }

  ul.dropdown-menu.show a.dropdown-item {
    margin-left: 20px;
  }

  ul.menu-items .nav-link {
    padding: 0rem 1.35rem .2rem;
    display: flex;
    justify-content: space-between;
    width: 100%;
    font-size: 18px;
    font-family: 'Font-Helvetica-bold';
  }


  .navbar-container input[type="checkbox"] {
    position: absolute;
    display: block;
    height: 32px;
    width: 30px;
    top: 20px;
    right: 20px;
    z-index: 5;
    opacity: 0;
    cursor: pointer;
    visibility: visible;
  }

  .svg-icon svg {
    position: absolute;
    right: 19px;
    top: 25px;
  }

  .navbar-container .hamburger-lines {
    /* display: block;
      height: 28px; */
    width: 28px;
    margin-right: 20px;
    /* position: absolute;
      top: 20px;
      left: 20px;
      z-index: 2;
      display: flex;
      flex-direction: column;
      justify-content: space-between; */
  }

  .hamburger-lines span {
    padding: 0;
  }

  .navbar-container .hamburger-lines .line {
    display: block;
    height: 2px;
    width: 100%;
    background: #323232;
    margin-bottom: 5px;
  }

  /* .navbar-container .hamburger-lines .line1 {
    transform-origin: 0% 0%;
    transition: transform 0.3s ease-in-out;
  } */

  /* .navbar-container .hamburger-lines .line2 {
    transition: transform 0.2s ease-in-out;
  } */

  /* .navbar-container .hamburger-lines .line3 {
    transform-origin: 0% 100%;
    transition: transform 0.3s ease-in-out;
  } */

  .navbar .menu-items {
    padding-top: 50px;
    background: #fff;
    height: 100vh;
    width: 100%;
    transform: translate(150%);
    display: flex;
    flex-direction: column;
    padding-left: 40px;
    transition: transform 0.5s ease-in-out;
    overflow: scroll;
    align-items: flex-end;
  }

  .main-header {
    order: -1;
    margin: 20px 42px;
  }

  .navbar .menu-items li {
    padding: 0.3rem 0rem;
    font-size: 1.1rem;
    font-weight: 500;
    width: 100%;
    border-bottom: 1px solid #323232;
  }

  ul.dropdown-menu.show li {
    border-bottom: none;
  }

  .dropdown-menu ul.menu-items li:first-child {
    margin-top: 20px;
  }

  .logo {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 2.5rem;
  }

  /* .hamburger-lines:after {
    content: 'x';
    display: block;
    position: absolute;
    z-index: 1;
    font-size: 30px;
    top: 13px;
    right: 28px;
} */


  .navbar-container input[type="checkbox"]:checked~.menu-items {
    transform: translateX(0);
    position: absolute;
    top: 0;
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line1 {
    transform: rotate(45deg);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line2 {
    transform: scaleY(0);
  }

  .navbar-container input[type="checkbox"]:checked~.hamburger-lines .line3 {
    transform: rotate(-45deg);
  }

  .navbar .menu-items {
    padding-left: 0px;
  }

  .menu-items {
    align-items: flex-start;
  }

  .navbar-container.container {
    padding: 0;
  }

  nav.navbar {
    padding: 0;
  }

  a.Header-a span {
    margin-right: 50px;
  }

  header.header-head a.navbar-brand {
    margin-left: 15px;
    margin-top: 15px;
  }

  .shopping-list img:first-child {
    margin: 4px 60px 0 0;
  }

  .mobile-icon svg {
    position: absolute;
    right: 20px;
    top: 24px;
  }

  ul.dropdown-menu.open-auto li {
    border-bottom: none;
  }

}

@media (max-width: 500px) {
  .navbar-container input[type="checkbox"]:checked~.logo {
    display: none;
  }

}