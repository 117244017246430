.main-container {
    margin: 0 2rem;
    display: flex;
}

.main-containt {
    margin-left: 2rem;
}

.uppar-containt {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
    opacity: .7;
}

.color-option {
    display: flex;

}

.color-option .option {
    background-color: black;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
}

.color-option .option-a {
    background-color: #b9b7b7;

}


.cart-btn {
    background-color: black;
    color: aliceblue;
    font-weight: bolder;
    width: 95%;
    height: 2.2rem;
    border-radius: 1rem;
}

.collapsible {
    background-color: white;
    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
    padding: 1rem;
    width: 100%;
    border: none;
    text-align: left;
    /* outline: none; */
}

.active,
.collapsible:hover {
    opacity: .7;
}

.content {
    padding: 0 18px;
    display: none;
    /* overflow: hidden; */
    /* background-color: #f1f1f1; */
}

.side-bar {
    /* width: 350px; */
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #111;
    /* overflow-x: hidden; */
    transition: 0.5s;
    /* padding-top: 60px; */
}

.close-btn{
    color:#b9b7b7
}