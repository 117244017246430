.Conditions-points ul {
    list-style: disc;
    font-weight: 600;
}
.term-headings h2 {
    font-size: 54px;
    font-family: 'Font-Helvetica-Thin';
    padding-bottom: 40px;
}
.Conditions-header h2 {
    font-size: 18px;
    font-weight: 500;
    max-width: 420px;
    line-height: 25px;
}
.conditions-per p {
    color: #323232;
    font-size: 14px;
    font-family: 'Font-Helvetica-Thin';
    max-width: 570px;
    margin-bottom: 20px;
}
.text-bottom p {
    font-weight: 600;
    margin: 30px 0;
}
.text-bottom2 p {
    font-weight: 600;
    margin-bottom: 60px;
}
.text-bottom2 p {
    color: #323232;
    font-size: 14px;
    font-family: 'Font-Helvetica-Thin';
    max-width: 38%;
    margin-bottom: 20px;
    margin-right: 11%;
}
.Conditions-points {
    width: 65%;
}
.cookiep {
    padding-top: 10%;
}
.conditions-per a {
    text-decoration: underline;
}

@media screen and (max-width: 576px){
    .term-headings {
        margin-top: 30px;
    }
    hr.hori-line {
        margin: 0;
    }
    .text-bottom2 p {
        margin-right: 0px;
    }
  
}