.Conditions-points ul {
    list-style: disc;
    font-weight: 600;
}
.term-headings h2 {
    font-size: 32px;
    color: #323232;
    font-weight: 400;
}
.Conditions-header h2 {
    font-size: 18px;
    font-weight: 500;
    max-width: 420px;
    line-height: 25px;
}
.conditions-per p {
    margin: 0 0 20px;
    font-weight: 600;
}
.text-bottom p {
    font-weight: 600;
    margin: 30px 0;
}
.text-bottom2 p {
    font-weight: 600;
    margin-bottom: 60px;
}
.text-bottom2 p {
    max-width: 610px;
    float: right;
}
.top-spacing {
    padding-top: 10%;
}