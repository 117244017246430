.main-container {
    margin: 2rem;
    display: flex;
}

.main-containt {
    margin-left: 2rem;
}

.uppar-containt {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    margin-bottom: 2rem;
    opacity: .7;
}

.color-option {
    display: flex;

}

.color-option .option {
    background-color: black;
    height: 1.2rem;
    width: 1.2rem;
    border-radius: 50%;
}

.color-option .option-a {
    background-color: #b9b7b7;

}


.cart-quantity span {
    display: flex;
    justify-content: center;
    padding-right: 10px;
    font-size: 16px;
    padding-top: 4px;
}

.cart-btn {
    background-color: black;
    color: aliceblue;
    font-weight: bolder;
    width: 95%;
    height: 2.2rem;
    border-radius: 1rem;
}

.collapsible {
    background-color: white;
    font-size: 1rem;
    font-weight: bolder;
    cursor: pointer;
    padding: 1rem;
    width: 100%;
    border: none;
    text-align: left;
    /* outline: none; */
}

.accordion-collapse.collapse {
    visibility: visible;
}


.accordion-item .accordion-button {
    color: #000;
}

.active,
.collapsible:hover {
    opacity: .7;
}

.content {
    padding: 0 18px;
    display: none;
}

.accordion-item .accordion-button {
    position: relative;
}


.accordion-item .accordion-button.collapsed::after {
    content: '';
    background-image: url(../../public/assets/Main_Image_01.webp);
    background-position: center;
    background-size: cover;
    width: 16px;
    height: 16px;
    display: none;
    position: absolute;
    right: 0;
}

.product-overview {
    color: #000;
}

.anglewhendals {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    cursor: pointer;
    padding: 3px 6px;
    font-size: 14px;
    position: absolute;
    top: 0;
    right: 0;
}

.accordion-item .accordion-button {
    width: 100%;
    text-align: left;
}

.accordion-item .accordion-button.collapsed i.fa.fa-minus {
    display: none;
}

.accordion-item {
    border-bottom: 1px solid #000;
    padding: 20px 0;
}

.accordion-body {
    margin: 20px 0 0;
}

.side-bar {
    /* width: 350px; */
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    top: 0;
    right: 0;
    background-color: #111;
    /* overflow-x: hidden; */
    transition: 0.5s;
    /* padding-top: 60px; */
}

.close-btn {
    color: #b9b7b7
}

.main-container.top-page {
    padding-top: 10%;
}


/* Slider */

.slider {
    -webkit-appearance: none;
    appearance: none;
    width: 100%;
    height: 20px;
    background: var(--gray);
    outline: none;
    opacity: 0.7;
    -webkit-transition: .2s;
    transition: opacity .2s;

}

.slider:hover {
    opacity: 1;
}

.slider::-webkit-slider-thumb {
    -webkit-appearance: none;
    appearance: none;
    width: 25px;
    /* Set a specific slider handle width */
    height: 25px;
    /* Slider handle height */
    background: var(--green);
    /* Green background */
    cursor: pointer;
    /* Cursor on hover */
    border-radius: 50%;
}

.slider-wrapper {
    position: relative;
    max-width: 48rem;
    margin: 0 auto;
}

.slider-img {
    display: flex;
    overflow-x: hidden;
    scroll-snap-type: x mandatory;
    scroll-behavior: smooth;
}

.accordion-item img {
    width: 15px;
    height: 15px;
    margin-right: 7px;
}

.slider-img img {
    flex: 1 0 100%;
    scroll-snap-align: start;
    object-fit: cover;
}

.slider-nav {
    display: flex;
    column-gap: 0.50rem;
    position: absolute;
    top: 52rem;
    left: 47%;
    transform: translateX(-50%);
    z-index: 1;
}

.slider-nav a {
    width: 0.60rem;
    height: 0.60rem;
    border-radius: 50%;
    background-color: #323232;
    opacity: 0.75;
    transition: opacity ease 250ms;
}

.slider-nav a:hover {
    opacity: 1;
}


/* Gird */

.grid-111_1200 {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 20px;
    max-width: 1200px;
    margin: 0 auto;
    margin: 30px 0 100px;
}


.uppar-containt b {
    font-size: 14px;
}

.cart-btn:hover {
    background-color: #323232b3;
}

.main-slider {
    width: 100%;
    margin: auto;
}

.slider {
    width: 100%;
    height: 100%;
}

.quantity input {
    border: 1px solid #c9c9c9;
    max-width: 70px;
    margin-top: 10px;
    padding: 8px 3px 8px 10px;
}

.quantity input:focus-visible {
    outline: 0;
}

.main-viewwidth {
    margin-top: 3%;
    margin-bottom: 8%;
}

#slider {
    margin: 0 auto;
    width: 800px;
    max-width: 100%;
    text-align: center;
}

#slider input[type=radio] {
    display: none;
}

#slider label {
    cursor: pointer;
    text-decoration: none;
}

#overflow {
    width: 100%;
    overflow: hidden;
}

#slide1:checked~#slides .inner {
    margin-left: 0;
}

#slide2:checked~#slides .inner {
    margin-left: -100%;
}

#slide3:checked~#slides .inner {
    margin-left: -200%;
}

#slide4:checked~#slides .inner {
    margin-left: -300%;
}

#slides .inner {
    transition: margin-left 800ms cubic-bezier(0.770, 0.000, 0.175, 1.000);
    width: 400%;
    line-height: 0;
    height: 300px;
}

#slides .slide {
    width: 25%;
    float: left;
    justify-content: center;
    align-items: center;
    color: #fff;
}

#controls {
    margin: -180px 0 0 0;
    width: 100%;
    height: 50px;
    z-index: 3;
    position: relative;
}

#controls label {
    transition: opacity 0.2s ease-out;
    display: none;
    width: 50px;
    height: 50px;
    opacity: .4;
}

#controls label:hover {
    opacity: 1;
}

#slide1:checked~#controls label:nth-child(2),
#slide2:checked~#controls label:nth-child(3),
#slide3:checked~#controls label:nth-child(4),
#slide4:checked~#controls label:nth-child(1) {
    background: url(https://image.flaticon.com/icons/svg/130/130884.svg) no-repeat;
    float: right;
    margin: 0 -50px 0 0;
    display: block;
}

#slide1:checked~#controls label:nth-last-child(2),
#slide2:checked~#controls label:nth-last-child(3),
#slide3:checked~#controls label:nth-last-child(4),
#slide4:checked~#controls label:nth-last-child(1) {
    background: url(https://image.flaticon.com/icons/svg/130/130882.svg) no-repeat;
    float: left;
    margin: 0 0 0 -50px;
    display: block;
}

#bullets {
    margin: 150px 0 0;
    text-align: center;
}

#bullets label {
    display: inline-block;
    width: 8px;
    height: 8px;
    border-radius: 100%;
    background: #ccc;
    margin: 0 3px;
}

#slide1:checked~#bullets label:nth-child(1),
#slide2:checked~#bullets label:nth-child(2),
#slide3:checked~#bullets label:nth-child(3),
#slide4:checked~#bullets label:nth-child(4) {
    background: #444;
}

.main-slider .slide-content img {
    width: 100%;
}

@media screen and (max-width: 900px) {

    #slide1:checked~#controls label:nth-child(2),
    #slide2:checked~#controls label:nth-child(3),
    #slide3:checked~#controls label:nth-child(4),
    #slide4:checked~#controls label:nth-child(1),
    #slide1:checked~#controls label:nth-last-child(2),
    #slide2:checked~#controls label:nth-last-child(3),
    #slide3:checked~#controls label:nth-last-child(4),
    #slide4:checked~#controls label:nth-last-child(1) {
        margin: 0;
    }

    #slides {
        max-width: calc(100% - 140px);
        margin: 0 auto;
    }
}

@media screen and (max-width:576px) {
    .grid-111_1200 {
        margin: 30px 0 25px;
    }

    .slider-nav {
        top: 30rem;
    }

    #slides {
        max-width: none;
    }
    .main-slider {
        margin-top: 30px;
    }

}