.footer1 {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
}

.footer2 {
    width: 40%;
    height: auto;
    min-height: 433px;
    background-color: #323232;
    padding: 25px;
    border-right: 1px solid #595959;
}

.footer-4-div a.navbar-brand span {
    color: #f0f0f0;
}


.footer3 {
    width: 60%;
    height: auto;
    min-height: 433px;
    background-color: #323232;
    padding: 25px;
    border-right: 1px solid #595959;
}



.footer2-sub {
    width: 100%;
    height: auto;
    min-height: 304px;
    background-color: #323232;
    text-align: start;
    padding-top: 80px;

}

.footer2-sub2 {
    width: 100%;
    text-align: center;
    padding-top: 0px;
    margin-top: 10px;

}

.footer2-p {
    color: white;
    text-align: left;
    padding-right: 40px;
}

.footer-p1 {
    font-size: 29px;
    font-weight: 600;
    line-height: 37.7px;
    max-width: 300px;
    font-family: 'Font-Helvetica-bold';
    color: #f0f0f0;
}

.footer-p2 {
    margin-top: 25px;
    font-size: 16px;
    max-width: 410px;
    font-weight: 300;
    color: #f0f0f0;
    font-family: 'Font-Helvetica';

}

.footer3-label {
    font-weight: 500;
    margin-bottom: 25px;
    font-size: 14px;
    color: #f0f0f0;
}

.footer3-input {
    width: 95%;
    height: 62px;
    border-radius: 45px;
    background-color: #323232;
    border: 1px solid white;
    color: white;
    font-size: 25px;
    margin-top: 25px;
}

.footer3-p {
    color: white;
    margin-top: 25px;
}

.footer3-sub {
    margin-top: 16%;
    width: 100%;
    position: relative;
}

.footer3-btn {
    padding: 15px 40px;
    border-radius: 40px;
    background-color: white;
}

.footer4 {
    display: flex;
    flex-wrap: wrap;
}

.footer4-sub {
    width: 20%;
    background-color: #323232;
    border-top: 1px solid #595959;
    border-right: 1px solid #595959;

}

.footer3-sub button.footer3-btn:hover {
    background-color: #323232;
    border: 2px solid #ffffff;
}

.footer4-sub2 {
    width: 70%;
    margin-left: 25px;

}

.footer-4-div {
    margin-top: 40px;
}

.footer4-a {
    color: white;
    font-size: 27px;
    font-weight: 700;
    cursor: pointer;


}

.footer-4-p1 {
    margin-top: 20px;
}

.footer4-sub2 a {
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;

}

.footer-4-a:hover {
    text-decoration: none;
    color: white;
}

.footer4-para {
    padding-top: 10px;
}

.footer-ed-p {
    margin-top: 70px;
    font-size: 13px;
    color: white;
    font-weight: 700;
}

.footer-end-a {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 700;
    
}


.footer-4-p {
    color: white;
    font-size: 16px;
    font-weight: 600;
    padding: 0;
}



.footer3-sub button.footer3-btn {
    position: absolute;
    top: 48px;
    right: 40px;
    font-size: 21px;
    font-weight: 600;
    border: 2px solid #fff;

}

.footer3-sub button.footer3-btn:hover {
    background-color: #323232;
    border: 2px solid #ffffff;
    color: #fff;
}

.search-container {
    display: flex;
    align-items: center;
    border: 1px solid #ccc;
    border-radius: 30px;
    overflow: hidden;
}

.search-input {
    flex: 1;
    padding: 15px;
    border: none;
    font-size: 16px;
    background-color: transparent;
}

.search-btn {
    background-color: #ffffff;
    color: #323232;
    padding: 12px 40px;
    cursor: pointer;
    font-size: 20px;
    font-weight: 500;
    border-radius: 35px;
    border: 1px solid #f0f0f0;
}



.search-btn:hover {
    background-color: #323232;
    color: #fff;
    border: 1px solid #f0f0f0;
}


@media only screen and (max-width: 768px) {
    .footer {
        width: 100%;
    }

    .footer1 {
        display: flex;
        flex-direction: column;
        align-items: center;
    }

    .footer2,
    .footer3 {
        width: 100%;
        border-right: none;
        border-bottom: 1px solid white;
        /* margin-bottom: 20px; */
    }

    .footer4-sub {
        width: 100%;
        border-right: none;
    }

    .footer4 {
        flex-wrap: wrap;
    }

    .footer4-sub {
        width: 50%;
        height: 250px;
        border-top: none;
        text-align: start;
        margin-top: -20px;
        padding-left: 20px;
        border-top: 1px solid white;
        padding-bottom: 10px;
        /* border-right: 1px solid white; */
    }

    .spark {
        border-right: 1px solid white;
    }

    .sub-contact {
        width: 100%;

    }

    .footer4-sub2 {
        width: 100%;
        margin-left: 0;
    }

    .footer3-input {
        width: 100%;
        max-width: 300px;
    }

    .footer3-btn {
        width: 100%;
        max-width: 300px;
        margin-top: 20px;
    }

    #shop {
        animation: none;
        /* Remove the animation for smaller screens */
    }

    .footer-ed-p {
        margin-top: 20px;

    }
}

@media (max-width: 768px) {
    .footer3-sub button.footer3-btn {
        position: static;
    }

    .footer2-sub {
        padding: 10px;
    }

    .footer2 {
        min-height: auto;
    }

    .footer2-sub {
        min-height: auto;

    }

    .footer3.col-span-8 {
        min-height: 240px;
    }
    .search-container {
        margin-bottom: 30px;
    }
}

@media (max-width: 480px) {
    .search-btn {
        padding: 7px 18px;
        font-size: 16px;
    }

    .search-input {
        padding: 7px;
        width: 100%;
    }

    .footer-p2 {
        font-size: 13px;
    }
   


}