h1.prodect-heading {
    font-size: 51px;
    margin-bottom: 60px;
    font-family: 'Font-Helvetica-Thin';


}
.img-item img{
    width: 100%;
}
span.badge.bg-secondary {
    margin: 14px;
}

.items-prodects .badge.bg-secondary {
    background-color: transparent !important;
    border: 1px solid #000;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 14px;
    font-weight: 300;
}

.items-prodects p {
    font-size: 14px;
    font-weight: 600;
    margin-top: 12px;
    font-family: 'Font-Helvetica-bold';

}

.items-prodects span {
    font-size: 14px;
    font-weight: 600;
}
.items-prodects img{
    filter: grayscale(6);
}

.items-prodects img:hover{
    filter: grayscale(0);
}       



