.My-Addresses h1 {
    font-size: 23px;
    font-weight: 700;
    margin-bottom: 20px;
}

/* MyAddresses.css */



.My-Addresses {
    border-bottom: 1px solid #000;
    padding: 30px 0;
}

.My-Addresses p {
    font-size: 18px;
}

address.address {
    padding: 30px 0;
}

address.address {
    max-width: 320px;
    color: #919191;
    font-size: 20px;
    font-weight: 700;
}

.btn-edit a {
    padding: 0 20px 0 0;
}

.btn-edit a {
    padding: 0 20px 0 0;
    text-decoration: underline;
    font-size: 20px;
    font-weight: 700;
}

.btn-edit {
    color: #323232;
    margin-top: 25px;
}

.addes {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    margin: 30px 0;
}

.add-new a {
    border: 1px solid #000;
    padding: 10px 30px;
    border-radius: 40px;
    text-decoration: none;
    font-weight: 700;
}

.add-new {
    margin-bottom: 50px;
}

.aderssform h1 {
    font-size: 26px;
    font-family: 'Font-Helvetica-Thin';
    border-bottom: 1px solid #525252;
    padding-bottom: 20px;
}

.aderssform input {
    margin-bottom: 30px;
}

.aderssform .form-select {
    border: 1px solid #595959;
    width: 100%;
    padding: 11px;
    margin-bottom: 30px;

}

.btn-add button {
    margin: 50px 0;
}

.btn-add button.btn.add-addres {
    background-color: #686F4C;
    padding: 10px 75px;
    color: #f0f0f0;
    margin-right: 27px;
}

.aderssform input {
    margin-bottom: 30px;
    border-radius: 0;
}

.inner-form {
    overflow-y: scroll;
    height: 600px;
}

.popup-overlay {
    position: fixed;
    top: 18%;
    left: 29%;
    width: 800px;
    height: 500px;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 2;
}

.add-new button {
    border: 1px solid #323232;
    padding: 10px 30px;
    border-radius: 30px;
    font-family: 'Font-Helvetica-bold';

}

.inner-form select.form-select {
    border: 1px solid #d5d5d5;
    padding: 14px 9px;
    width: 100%;
    margin-bottom: 25px;
}

.inner-form .form-control {
    margin-bottom: 28px;
    padding: 23px 0;
}

.inner-form label.form-label {
    margin-bottom: 15px;
}

.modal-content span.close svg {
    float: right;
}

.address-form h1 {
    font-size: 23px;
    font-family: 'Font-Helvetica-Thin';
    padding-bottom: 15px;
    border-bottom: 1px solid #595959;
    margin-bottom: 35px;
}

.popup-content .modal-content {
    border: none;
}

.popup-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
}

.address-form {
    /* Your existing address form styles */
}

@media screen and (max-width: 576px) {
    .My-Addresses {
        border-bottom: none;
        padding: 30px 0 0;
    }

    address.MyAccount h2 {

        margin: 0px 0 16px;
    }

    address.MyAccount h2 {
        font-weight: 500;
    }

    .My-Addresses h1 {
        font-weight: 500;
    }

    .account-info.phone {
        max-width: 100%;
    }

    .account-info {
        margin-top: 8px;
    }

    .account-info.phone {
        margin-top: 15px;
    }

    .forminfo-btn {
        float: none;
    }

    .popup-overlay {
        left: 0%;
        width: auto;
    }

    .btn-add button.btn.add-addres {
        padding: 15px 50px;
        margin-right: 27px;
        font-weight: 600;
    }
}