.thankyou-heading h1 {
    font-size: 42px;
    font-weight: 500;
    padding-bottom: 20px;
}

.thankyou-heading.text-center p,
.thankyou-heading.text-centerspan {
    padding-bottom: 20px;
    font-size: 23px;
}

.container.main-width {
    max-width: 912px;
}

.thankyou-heading {
    padding-bottom: 90px;
}

.product-img img {
    width: 150px;
    margin-right: 20px;
}

.product-option p {
    color: #858585;
    font-size: 18px;
}

.product-option b {
    font-size: 22px;
}

b.thankyou-p-4 {
    font-size: 20px;
    margin-left: 40%;
}

hr.hr-line {
    border: 1px solid #d5d5d5;
    margin: 70px 0 30px;
}

.total h3 {
    font-size: 20px;
    font-weight: 600;
}

.total p {
    color: #898989;
}

.total-table .table td {
    border: none;
    padding: 7px 0;
    width: 100%;
}

.total-table {
    margin-left: 40px;
}

table.tables tbody tr td {
    padding: 21px 0;
    width: 100%;
    font-size: 23px;
}

table.tables {
    border-top: 3px solid #d5d5d5;
}

.Delivery h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

ul {
    list-style: none;
}

.Delivery ul li {
    padding: 0 0 6px 0;
    font-weight: 500;
}

.Delivery ul li:last-child {
    padding-top: 15px;
}

.Billing h4 {
    font-size: 20px;
    font-weight: 500;
    margin-bottom: 20px;
}

.Billing ul li {
    padding: 0 0 6px 0;
    font-weight: 500;
}


.Browsing a {
    margin: 80px 0;
    display: block;
    font-size: 18px;
    text-decoration: underline;
    color: #323232;
}