.g-banner-icon img {
    width: 100px;
    border: 3px solid #000;
    border-radius: 66px;
}

.g-banner-icon h2 {
    font-size: 28px;
    margin-left: 20px;
    margin-top: 35px;
    font-family: 'Font-Helvetica-Thin';

}

.top-headers {
    max-width: 940px;
    margin: 0 auto;
}

.top-headers p {
    font-size: 16px;
    font-family: 'Font-Helvetica-Thin';
}

.top-headers h1 {
    margin-bottom: 15px;
    font-family: 'Font-Helvetica-bold';
    color: #323232;
    font-size: 16px;
}

.g-banner-icon {
    max-width: 940px;
    margin: 0 auto;
    padding-top: 8.5%;
}

.navbat-controler button:focus {
    color: #686F4C;
    border-top: 4px solid #686F4C;
}

.navbat-controler button {
    border-top: 4px solid transparent;   
    padding: 10px 90px;
    font-family: 'Font-Helvetica-bold';
    font-size: 14px;
}

.profile-bg img {
    width: 100%;
    height: 250px;
    position: absolute;
    z-index: -1;
    opacity: calc(var(--pw-cover-photo-opacity-desktop, 60)*1%);
}

.navbat-controler button:focus-visible {
    outline: none;
}

.navbat-controler {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    max-width: 1280px;
    margin: 0 auto;
    padding-top: 4%;
}

.component-border {
    /* border: 1px solid #ccc; */
    padding: 10px;
    margin-top: 10px;
}

.navbat-controler button.active {
    border-top: 4px solid #686F4C;
}


.navbat-controler button:active {
    border-top: 4px solid #686F4C;
}

.laced-orders {
    border-top: 1px solid #000;
    border-bottom: 1px solid #000;
    padding: 80px;
    margin-bottom: 50px;
}

.navbat-controler button:hover {
    border-top: 4px solid #686F4C;
}

.laced-orders h3 {
    font-size: 20px;
    font-family: 'Font-Helvetica-bold';
    color: #595959;

}

.Browsings.text-center a {
    font-size: 14px;
    font-family: 'Font-Helvetica-bold';
    color: #595959;

}


.Browsings {
    margin: 30px 0;
    display: block;
    font-size: 18px;
    text-decoration: underline;
    color: #323232;
}

.profile-bg i.fa-solid {
    position: absolute;
    transform: translate(36px, 37px);
    color: #f9f9f9;
    cursor: pointer;
}

@media screen and (max-width: 576px) {
    .laced-orders {
        padding: 40px 30px;
    }

    .top-headers {
        max-width: 345px;
        margin: 0 auto;
    }

    .navbat-controler button {
        padding: 100px 20px 0;
    }

    .g-banner-icon {
        padding-top: 50%;
        padding-left: 30px;
    }

    .g-banner-icon h2 {
        margin-left: 0px;
        margin-top: 15px;

    }

    .navbat-controler button {
        padding: 0px 15px 0;
        word-break: break-all;
    }

    .profile-bg i.fa-solid {
        transform: translate(17px, 26px);
    }

    .My-Addresses {
        flex-direction: column;
    }

   
}